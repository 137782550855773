export const initialQuestions = [
  {
    question:
      "Quantas medalhas o Brasil conquistou nos Jogos Olímpicos de Paris?",
    options: ["20 medalhas", "21 medalhas", "25 medalhas"],
    answer: "20 medalhas",
  },
  {
    question: "Qual é a modalidade que mais trouxe medalhas para o Brasil?",
    options: ["Natação", "Vôlei", "Judô"],
    answer: "Judô",
  },
  {
    question:
      "Em quais edições dos Jogos Olímpicos o Brasil teve recorde de medalhas de ouro?",
    options: [
      "Londres (1948) e Roma (1960)",
      "Rio (2016) e Tóquio (2020)",
      "Barcelona (1992) e Sydney (2000)",
    ],
    answer: "Rio (2016) e Tóquio (2020)",
  },
  {
    question:
      "Qual modalidade conquistou a primeira medalha para o Brasil em Paris 2024?",
    options: ["Ginástica artística", "Judô", "Skate"],
    answer: "Judô",
  },
  {
    question:
      "Em qual Jogos Olímpicos o Brasil teve recorde total de medalhas?",
    options: [
      "Rio - 2016, 19 medalhas",
      "Tóquio - 2020, 21 medalhas",
      "Sydney - 2000, 23 medalhas",
    ],
    answer: "Tóquio - 2020, 21 medalhas",
  },
  {
    question:
      "Qual foi a atleta feminina com maior número de participações olímpicas?",
    options: [
      "Jaqueline (vôlei) - 7",
      "Formiga (futebol) - 7",
      "Fabi (vôlei) - 7",
    ],
    answer: "Formiga (futebol) - 7",
  },
  {
    question: "A equipe de ginástica artística é composta por quantas atletas?",
    options: ["6", "5", "7"],
    answer: "5",
  },
  {
    question: "Qual medalha o Isaquias Queiroz conquistou em Paris 2024?",
    options: ["Prata", "Ouro", "Bronze"],
    answer: "Prata",
  },
  {
    question: "Quantas medalhas Rebeca Andrade conquistou em Paris 2024?",
    options: ["5", "2", "4"],
    answer: "4",
  },
  {
    question:
      "Quantas medalhas de ouro o Brasil conquistou até hoje em Jogos Olímpicos?",
    options: ["35", "40", "32"],
    answer: "40",
  },
  {
    question:
      "Qual o nome da dupla de vôlei de praia que ganhou ouro em Paris 2024?",
    options: ["Ana Patrícia e Duda", "Gabi e Flavinha", "Duda e Marcela"],
    answer: "Ana Patrícia e Duda",
  },
  {
    question:
      "Quais foram os porta-bandeiras do Brasil nos Jogos Olímpicos de Paris 2024?",
    options: [
      "Rebeca Andrade (ginástica) e Rodrigo Pessoa (Hipismo)",
      "Isaquias Queiroz (canoagem) e Raquel Kochhann (rugby)",
      "Ana Sátila (canoagem) e Gabi Guimarães (Vôlei)",
    ],
    answer: "Isaquias Queiroz (canoagem) e Raquel Kochhann (rugby)",
  },
  {
    question:
      "Quem foi o primeiro porta-bandeira do Brasil na história dos Jogos?",
    options: [
      "Guilherme Paraense (Tiro Esportivo)",
      "Sylvio de Magalhães Padilha (Atletismo)",
      "Alfredo Gomes (Atletismo)",
    ],
    answer: "Guilherme Paraense (Tiro Esportivo)",
  },
  {
    question:
      "Qual foi a edição dos Jogos Olímpicos que teve pela primeira vez uma atleta mulher brasileira?",
    options: ["Amsterdã - 1928", "Berlim - 1936", "Los Angeles - 1932"],
    answer: "Los Angeles - 1932",
  },
  {
    question:
      "Quem foi o primeiro atleta olímpico brasileiro a conquistar uma medalha?",
    options: [
      "Adhemar Ferreira (atletismo)",
      "Afrânio Antônio da Costa (tiro)",
      "Affonso Évora (basquete)",
    ],
    answer: "Afrânio Antônio da Costa (tiro)",
  },
  {
    question: "Quem foi o primeiro medalhista Olímpico Brasileiro no Surf?",
    options: ["Gabriel Medina", "Ítalo Ferreira", "Filipe Toledo"],
    answer: "Ítalo Ferreira",
  },
  {
    question: "Quais foram os medalhistas brasileiros de surfe em Paris 2024?",
    options: ["Medina e Tati", "Italo e Tati", "Medina e Italo"],
    answer: "Medina e Tati",
  },
  {
    question:
      "Em qual edição dos Jogos Olímpicos o Brasil conquistou a primeira medalha de ouro no futebol?",
    options: ["Pequim 2008", "Montreal 1976", "Rio 2016"],
    answer: "Rio 2016",
  },
  {
    question:
      "Em qual edição dos Jogos Olímpicos teve a estreia da modalidade Skate Street?",
    options: ["Rio 2016", "Londres 2012", "Tóquio 2020"],
    answer: "Tóquio 2020",
  },
  {
    question:
      "Quem foi a primeira brasileira a conquistar uma medalha da modalidade skate Street nos Jogos Olímpicos?",
    options: ["Pâmela Rosa", "Letícia Bufoni", "Rayssa Leal"],
    answer: "Rayssa Leal",
  },
  {
    question: "Qual medalha Rayssa Leal conquistou em Paris 2024?",
    options: ["Ouro", "Prata", "Bronze"],
    answer: "Bronze",
  },
  {
    question:
      "Em Paris, Rebeca Andrade se tornou a maior medalhista olímpica do Brasil. Quantas medalhas ela possui no total?",
    options: ["4 medalhas", "5 medalhas", "6 medalhas"],
    answer: "6 medalhas",
  },
  {
    question:
      "Qual modalidade participou dos Jogos Olímpicos pela primeira vez na edição de Paris?",
    options: ["Tiro com arco", "Surfe", "Breaking"],
    answer: "Breaking",
  },
  {
    question:
      "Em Paris, Valdileia Martins igualou o recorde brasileiro feminino no salto em altura. Qual altura ela saltou?",
    options: ["1.92m", "1.93m", "1.94m"],
    answer: "1.92m",
  },
  {
    question:
      "Qual é o novo recorde sul-americano do lançamento do dardo estabelecido pelo brasileiro Luiz Maurício em Paris?",
    options: ["85m90", "85m91", "85m92"],
    answer: "85m91",
  },
  {
    question:
      "Qual destes atletas fez sua estreia em Jogos Olímpicos na edição de Paris?",
    options: [
      "Alison dos Santos (Piu) - Atletismo",
      "Julia Soares - Ginástica Artística",
      "Ana Cristina - Vôlei de quadra",
    ],
    answer: "Julia Soares - Ginástica Artística",
  },
  {
    question:
      "O movimento 'Soares' é realizado em qual aparelho da ginástica artística?",
    options: ["Barras assimétricas", "Trave de equilíbrio", "Salto"],
    answer: "Trave de equilíbrio",
  },
  {
    question:
      "Quando foi a última vez que o Brasil ficou sem medalhas no vôlei de quadra dos Jogos Olimpícos?",
    options: ["Seul 1988", "Barcelona 1992", "Sydney 2000"],
    answer: "Seul 1988",
  },
  {
    question:
      "A goleira Lorena foi um dos destaques da seleção feminina de futebol em Paris. Quantos pênaltis ela defendeu na competição?",
    options: ["1", "2", "3"],
    answer: "2",
  },
  {
    question:
      "Quem é o atleta brasileiro com mais participações em Jogos Olímpicos de Verão?",
    options: ["Marta", "Rodrigo Pessoa", "Thaisa"],
    answer: "Rodrigo Pessoa",
  },
  {
    question:
      "A jogadora de futebol Marta se despediu dos Jogos Olímpicos com quantas medalhas conquistadas?",
    options: ["1", "2", "3"],
    answer: "3",
  },
  {
    question:
      "Quem é o(a) maior medalhista do boxe brasileiro em Jogos Olímpicos?",
    options: ["Bia Ferreira", "Adriana Araújo", "Servílio de Oliveira"],
    answer: "Bia Ferreira",
  },
  {
    question:
      "Em Paris, Hugo Calderano fez a melhor campanha da história do tênis de mesa brasileiro em Jogos Olímpicos. Em qual posição ele ficou?",
    options: ["4º lugar", "5º lugar", "6º lugar"],
    answer: "4º lugar",
  },
  {
    question:
      "Em Paris, Miguel Hidalgo fez a melhor campanha da história do triatlo brasileiro em Jogos Olímpicos. Em qual posição ele ficou?",
    options: ["9º lugar", "10º lugar", "11º lugar"],
    answer: "10º lugar",
  },
  {
    question:
      "Qual atleta se tornou a primeira brasileira a nadar uma final olímpica dos 1500m livre?",
    options: ["Mafe Costa", "Beatriz Dizotti", "Stephanie Balduccini"],
    answer: "Beatriz Dizotti",
  },
  {
    question:
      "Qual atleta conquistou a primeira vitória de uma brasileira no badminton em Jogos Olímpicos?",
    options: ["Juliana Viana", "Lorrane Oliveira", "Júlia Bergmann"],
    answer: "Juliana Viana",
  },
  {
    question: "Quantas medalhas de ouro o Brasil conquistou em Paris 2024?",
    options: ["0", "1", "3"],
    answer: "3",
  },
  {
    question:
      "Pela primeira vez na história o Brasil disputou uma final individual da ginástica rítmica nos Jogos Olímpicos. Qual atleta conquistou esse feito?",
    options: ["Flávia Saraiva", "Bárbara Domingos", "Camilla Lopes"],
    answer: "Bárbara Domingos",
  },
  {
    question:
      "Qual atleta colocou o Brasil novamente em uma final olímpica do salto triplo após 16 anos?",
    options: ["Almir Jr.", "Paulo André Camilo", "Renan Gallina"],
    answer: "Almir Jr.",
  },
  {
    question:
      "Na final dos 400m livre da natação em Paris, Guilherme Costa estabeleceu o novo recorde pan-americano da prova. Qual foi o tempo dele?",
    options: ["3min41seg76", "3min42seg76", "3min43seg76"],
    answer: "3min42seg76",
  },
  {
    question:
      "Qual foi o placar da semifinal do futebol feminino em Paris entre Brasil x Espanha?",
    options: [
      "Brasil 4 x 1 Espanha",
      "Brasil 3 x 2 Espanha",
      "Brasil 4 x 2 Espanha",
    ],
    answer: "Brasil 4 x 2 Espanha",
  },
  {
    question:
      "Em Paris, o Brasil conquistou uma medalha de bronze inédita no judô por equipes. Qual judoca venceu a luta de desempate que garantiu a medalha?",
    options: ["Larissa Pimenta", "Beatriz Souza", "Rafaela Silva"],
    answer: "Rafaela Silva",
  },
  {
    question:
      "Em qual aparelho da ginástica artística Rebeca Andrade conquistou a medalha de ouro nos Jogos Olímpicos de Paris?",
    options: ["Trave de equilíbrio", "Solo", "Salto sobre a mesa"],
    answer: "Solo",
  },
  {
    question:
      "Qual atleta sofreu um corte no supercílio no aquecimento antes de disputar uma final nos Jogos de Paris?",
    options: ["Gabi Portilho", "Flávia Saraiva", "Juliana Campos"],
    answer: "Flávia Saraiva",
  },
  {
    question:
      "Em Paris, Ana Sátila conquistou os melhores resultados na modalidade canoagem slalom do Brasil nos Jogos Olímpicos. Quais foram esses resultados?",
    options: [
      "4º lugar no K1, 5º lugar no C1 e 8º lugar no caiaque cross",
      "6º lugar no K1, 4º lugar no C1 e 3º lugar no caiaque cross",
      "4º lugar no K1, 8º lugar no C1 e 4º lugar no caiaque cross",
    ],
    answer: "4º lugar no K1, 5º lugar no C1 e 8º lugar no caiaque cross",
  },
  {
    question:
      "O Brasil teve três representantes na final do skate park masculino em Paris. Quem foram eles?",
    options: [
      "Pedro Barros, Luigi Cini e Augusto Akio",
      "Pedro Barros, Luigi Cini e Felipe Gustavo",
      "Kelvin Hoefler, Luigi Cini e Augusto Akio",
    ],
    answer: "Pedro Barros, Luigi Cini e Augusto Akio",
  },
  {
    question:
      "Nos Jogos de Paris, o Brasil voltou a disputar a final dos 400m livre feminino na natação pela primeira vez desde 1948. Quem foi a atleta que conseguiu esse feito?",
    options: ["Gabi Roncatto", "Mafê Costa", "Maria Paula Heitmann"],
    answer: "Mafê Costa",
  },
  {
    question:
      "Quem foi a primeira atleta da delegação brasileira a competir em Paris?",
    options: [
      "Ana Luiza Caetano (tiro com arco)",
      "Rayssa Leal (skate)",
      "Larissa Pimenta (judô)",
    ],
    answer: "Ana Luiza Caetano (tiro com arco)",
  },
  {
    question:
      "Quem conquistou a primeira medalha brasileira nos Jogos Olímpicos de Paris?",
    options: [
      "Rayssa Leal (skate)",
      "Willian Lima (judô)",
      "Rebeca Andrade (ginástica artística)",
    ],
    answer: "Willian Lima (judô)",
  },
  {
    question: "Quem conquistou o primeiro ouro do Brasil em Paris?",
    options: [
      "Rebeca Andrade (ginástica artística)",
      "Beatriz Souza (judô)",
      "Duda e Ana Patrícia (vôlei de praia)",
    ],
    answer: "Beatriz Souza (judô)",
  },
  {
    question:
      "Contra qual país a seleção feminina de vôlei disputou a medalha de bronze em Paris?",
    options: ["Estados Unidos", "China", "Turquia"],
    answer: "Turquia",
  },
  {
    question:
      "Onde foram realizadas as disputas do surfe nos Jogos Olímpicos de Paris?",
    options: ["Teahupo'o - Taiti", "Hossegor - França", "Biarritz - França"],
    answer: "Teahupo'o - Taiti",
  },
  {
    question:
      "Qual foi a posição final do Brasil no quadro de medalhas de Paris?",
    options: ["20º", "21º", "22º"],
    answer: "20º",
  },
  {
    question: "Quantas finais individuais a Rebeca Andrade disputou em Paris?",
    options: ["3", "4", "5"],
    answer: "4",
  },
  {
    question:
      "Qual destes atletas conquistou duas medalhas na modalidade do Judô nos Jogos Olímpicos de Paris?",
    options: ["Larissa Pimenta", "Rafaela Silva", "Rafael Silva 'Baby'"],
    answer: "Larissa Pimenta",
  },
  {
    question:
      "Nas quartas de final em Paris, a seleção feminina de futebol venceu a França por 1 x 0. Quem foi a autora do gol?",
    options: ["Adriana", "Gabi Portilho", "Formiga"],
    answer: "Gabi Portilho",
  },
  {
    question:
      "Quem conquistou a medalha de bronze para o taekwondo brasileiro em Paris?",
    options: ["Willian Lima", "Augusto Akio", "Edival Pontes 'Netinho'"],
    answer: "Edival Pontes 'Netinho'",
  },
  {
    question: "Qual foi a medalha conquistada por Augusto Akio em Paris?",
    options: ["Ouro", "Prata", "Bronze"],
    answer: "Bronze",
  },
  {
    question:
      "Em Paris, Giullia Penalber conquistou o melhor resultado do wrestling brasileiro nos Jogos Olímpicos. Qual foi sua posição final?",
    options: ["4º lugar", "5º lugar", "6º lugar"],
    answer: "5º lugar",
  },
  {
    question:
      "Quantas edições de Jogos Olímpicos a ginasta Flávia Saraiva já disputou?",
    options: ["1", "2", "3"],
    answer: "3",
  },
  {
    question:
      "Qual atleta disputou os Jogos Olímpicos e Paralímpicos em Paris?",
    options: ["Matheus Lima", "Gabriel Garcia", "Renan Gallina"],
    answer: "Gabriel Garcia",
  },
  {
    question:
      "Qual foi a edição dos Jogos Olímpicos que o Brasil mais disputou finais?",
    options: ["Londres 2012 (65)", "Pequim 2008 (38)", "Rio 2016 (71)"],
    answer: "Rio 2016 (71)",
  },
  {
    question:
      "Quem conquistou a primeira medalha de ouro feminina do Brasil em Jogos Olímpicos?",
    options: [
      "Rebeca Andrade (ginastica)",
      "Jackie Silva e Sandra Pires (vôlei de praia)",
      "Ana Marcela Cunha (natação)",
    ],
    answer: "Jackie Silva e Sandra Pires (vôlei de praia)",
  },
  {
    question:
      "Qual brasileira conquistou a primeira medalha individual feminina no Judô em Jogos Olímpicos?",
    options: ["Sarah Menezes", "Ketleyn Quadros", "Rafaela Silva"],
    answer: "Ketleyn Quadros",
  },
  {
    question:
      "Em qual edição dos Jogos Olímpicos o Brasil conquistou a primeira medalha de ouro no Vôlei Feminino?",
    options: ["Pequim 2008", "Roma 1960", "Atenas 2004"],
    answer: "Pequim 2008",
  },
  {
    question:
      "Quem é o único atleta brasileiro a conquistar a Medalha Pierre de Coubertin?",
    options: [
      "Vanderlei Cordeiro de Lima (atletismo)",
      "Guilherme Paraense (tiro)",
      "César Cielo (natação)",
    ],
    answer: "Vanderlei Cordeiro de Lima (atletismo)",
  },
  {
    question:
      "Quem foi a única brasileira a ganhar mais de três medalhas em uma única edição dos Jogos Olímpicos?",
    options: [
      "Rayssa Leal (skate)",
      "Ana Sátila Borges (canoagem)",
      "Rebeca Andrade (ginástica)",
    ],
    answer: "Rebeca Andrade (ginástica)",
  },
  {
    question:
      "Quantos atletas brasileiros foram classificados para Paris 2024?",
    options: ["288", "280", "277"],
    answer: "277",
  },
  {
    question: "Quantas modalidades o Brasil participou em Paris 2024?",
    options: ["40", "39", "38"],
    answer: "39",
  },
  {
    question: "Em que mês começaram os Jogos Olímpicos Paris 2024?",
    options: ["Junho", "Julho", "Agosto"],
    answer: "Julho",
  },
  {
    question:
      "Com qual país o Brasil disputou a final do futebol feminino em Paris 2024?",
    options: ["EUA", "França", "China"],
    answer: "EUA",
  },
  {
    question:
      "Qual país disputou a medalha de ouro com o Brasil no vôlei de praia feminino em Paris 2024?",
    options: ["Canadá", "Japão", "França"],
    answer: "Canadá",
  },
  {
    question: "Qual o nome do mascote de Paris 2024?",
    options: ["Thryges", "Phryge", "Flyges"],
    answer: "Phryge",
  },
  {
    question: "Como são chamadas as atletas de rugby do Brasil?",
    options: ["Yaras", "Leoas", "Brasucas"],
    answer: "Yaras",
  },
  {
    question: "Qual equipe é conhecida pelo nome de Leoas?",
    options: ["Handebol", "Futebol", "Vôlei"],
    answer: "Handebol",
  },
  {
    question:
      "Qual artista brasileira estava no mix da música da apresentação da Rebeca Andrade?",
    options: ["Ivete", "Ludmilla", "Anitta"],
    answer: "Anitta",
  },
  {
    question: "Qual atleta de natação tem o apelido de 'cachorrão'?",
    options: ["Guilherme Costa", "Gustavo Borges", "César Cielo"],
    answer: "Guilherme Costa",
  },
];
