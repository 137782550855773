import { useEffect, useState } from "react";
import {
  IconButton,
  Button,
  Divider,
  Stack,
  Typography,
  Tooltip,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import useQuiz from "../../stores/quiz";

import RestartAltIcon from "@mui/icons-material/RestartAlt";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import End from "./end";

const initialState = {
  state: false,
  correct: false,
  question: "",
};

const Quiz = () => {
  const navigate = useNavigate();
  const [answered, setAnswered] = useState(initialState);
  const { end, reset, questions, generateQuestion, addHit } = useQuiz();
  const currentQuestion = useQuiz((state) => state.current);

  useEffect(() => {
    generateQuestion();
  }, []);

  const restart = () => {
    reset();
    setAnswered(initialState);
    navigate("/");
  };

  const AnswerButton = (props: any) => {
    let color = "note";

    if (answered.state) {
      color = props.children === currentQuestion.answer ? "info" : "secondary";
    }

    return (
      <Button {...props} color={color}>
        {props.children}
      </Button>
    );
  };

  const checkAnswer = (answer: string) => {
    setAnswered({
      ...answered,
      state: true,
      correct: answer === currentQuestion.answer,
    });

    if (answer === currentQuestion.answer) {
      addHit();
    }
  };

  if (questions.length === 0) return <End restart={restart} />;

  if (!currentQuestion.question) return <></>;

  return (
    <>
      <Stack direction="column" alignItems="center" sx={{ mt: "-100px" }}>
        {answered.state && !answered.correct && (
          <Typography variant="h1" fontStyle="italic" color="secondary">
            Você errou!
          </Typography>
        )}
        {answered.state && answered.correct && (
          <Typography variant="h1" fontStyle="italic" color="info.main">
            Você acertou!
          </Typography>
        )}

        <Typography
          variant="h2"
          color="white"
          fontStyle="italic"
          textAlign="center"
          sx={{ my: 5 }}
        >
          {currentQuestion.question}
        </Typography>

        <Stack direction="row" spacing={2}>
          {currentQuestion.options.map((item: string) => (
            <AnswerButton
              key={item}
              size="large"
              variant="contained"
              onClick={() => {
                if (!answered.state) checkAnswer(item);
              }}
              sx={{
                fontSize: 35,
                fontWeight: "normal",
                fontStyle: "italic",
                p: "5px 20px",
                lineHeight: 1.2,
              }}
            >
              {item}
            </AnswerButton>
          ))}
        </Stack>
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        sx={{
          position: "absolute",
          top: 20,
          zIndex: 50,
        }}
      >
        <Button
          startIcon={<RestartAltIcon fontSize="large" />}
          color="note"
          variant="outlined"
          size="large"
          onClick={restart}
          sx={{
            fontSize: 30,
            fontStyle: "italic",
            p: "0 10px 0 10px",
          }}
        >
          Reiniciar
        </Button>
        <Button
          startIcon={<PowerSettingsNewIcon fontSize="large" />}
          color="note"
          variant="outlined"
          size="large"
          onClick={end}
          sx={{
            fontSize: 30,
            fontStyle: "italic",
            p: "0 10px 0 10px",
          }}
        >
          Finalizar
        </Button>
        <Button
          startIcon={<AddCircleIcon fontSize="large" />}
          color="note"
          variant="outlined"
          size="large"
          onClick={() => {
            setAnswered(initialState);
            generateQuestion();
          }}
          disabled={!answered.state}
          sx={{
            fontSize: 30,
            fontStyle: "italic",
            p: "0 10px 0 10px",
          }}
        >
          Próxima
        </Button>
      </Stack>
    </>
  );
};

export default Quiz;
