import { Outlet } from "react-router-dom";
import { Container, Box, Alert } from "@mui/material";

import Bg from "../assets/images/bg-header.webp";
import BgFooter from "../assets/images/bg-footer.webp";
import Logos from "../assets/images/logos.webp";
import LogoSportv from "../assets/images/logo-sportv-light.webp";
import shapeTopRight from "../assets/images/shape-top-right.webp";
import shapeBottomLeft from "../assets/images/shape-bottom-left.webp";

import useQuiz from "../stores/quiz";

function Layout() {
  const questions = useQuiz((state) => state.questions);

  return (
    <Container
      maxWidth={false}
      sx={{
        background: `#09043e`,
        backgroundImage: `url(${Bg})`,
        backgroundPosition: "top center",
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container
        sx={{
          display: "flex",
          height: "100vh",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        <Outlet />
      </Container>
      {questions.length > 0 && (
        <Box
          width="100%"
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            backgroundImage: `url(${BgFooter})`,
            backgroundPosition: "top center",
            backgroundSize: "100%",
            backgroundRepeat: "no-repeat",
            py: 10,
            textAlign: "center",
            zIndex: 1,
          }}
        >
          <img src={LogoSportv} width={150} />
        </Box>
      )}
    </Container>
  );
}

export default Layout;
