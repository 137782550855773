import { create } from "zustand";
import { initialQuestions } from "../utils/questions";

type State = {
  questions: Array<object>;
  current: any;
  answered: Array<object>;
  hits: number;
};

type Actions = {
  generateQuestion: () => void;
  reset: () => void;
  end: () => void;
  addHit: () => void;
};

function shuffleArray(array: any) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const questions = shuffleArray(initialQuestions).slice(0, 6);

const initialState: State = {
  questions,
  current: {},
  answered: [],
  hits: 0,
};

const useQuiz = create<State & Actions>((set, get) => ({
  ...initialState,

  generateQuestion: () => {
    const newQuestions: any = get().questions;
    const rand = Math.floor(Math.random() * newQuestions.length);

    set(() => ({ current: newQuestions[rand] }));

    set(() => ({
      questions: newQuestions.filter(
        (row: any) => row.question != newQuestions[rand].question,
      ),
    }));
  },

  addHit: () =>
    set(() => ({
      hits: get().hits + 1,
    })),

  reset: () => {
    const questions = shuffleArray(initialQuestions).slice(0, 6);

    set(() => ({
      ...initialState,
      questions,
    }));
  },

  end: () =>
    set(() => ({
      questions: [],
    })),
}));

export default useQuiz;
