import { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Container,
  Divider,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import Bg from "../../assets/images/bg-header-init.webp";
import BgFooter from "../../assets/images/bg-footer-init.webp";
import Gif from "../../assets/images/index-loop.gif";
import LogoSportv from "../../assets/images/logo-sportv-light.webp";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

const Index = () => {
  const navigate = useNavigate();
  const [count, setCount] = useState<null | number>(null);
  const [backdrop, setBackdrop] = useState(false);

  useEffect(() => {
    if (count === 0) {
      navigate("/start");
    } else if (count !== null) {
      const timer = setTimeout(() => {
        setCount(count - 1);
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [count, navigate]);

  const initCount = () => {
    setBackdrop(true);
    setCount(3);
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        background: `#09043e`,
        backgroundImage: `url(${Bg})`,
        backgroundPosition: "top center",
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <Typography fontSize={350} fontStyle="italic" fontWeight="bold">
          {count}
        </Typography>
      </Backdrop>
      {/* <Container
        sx={{
          display: "flex",
          height: "100vh",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        <Typography
          variant="h1"
          color="white"
          fontStyle="italic"
          textAlign="center"
          sx={{ my: 0 }}
        >
          Tá nos Jogos Olímpicos,
        </Typography>
        <Typography
          variant="h1"
          fontWeight={600}
          color="white"
          fontStyle="italic"
          textAlign="center"
          sx={{ my: 0 }}
        >
          tá no sportv!
        </Typography>
        <Button
          startIcon={<PlayCircleOutlineIcon fontSize="large" />}
          color="note"
          variant="outlined"
          size="large"
          onClick={() => {
            initCount();
          }}
          sx={{
            fontSize: 30,
            fontStyle: "italic",
            p: "0 10px 0 10px",
            mt: 5,
          }}
        >
          Começar
        </Button>
      </Container> */}
      <Box
        width="100vw"
        height="100vh"
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          cursor: "pointer",
          backgroundImage: `url(${Gif})`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          py: 10,
          textAlign: "center",
          zIndex: 1,
        }}
        onClick={() => {
          initCount();
        }}
      >
        <img src={LogoSportv} width={150} />
      </Box>
    </Container>
  );
};

export default Index;
