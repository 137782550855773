import { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import BgFooter from "../../assets/images/bg-footer.webp";
import LogoSportv from "../../assets/images/logo-sportv-light.webp";

import useQuiz from "../../stores/quiz";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const TypographyStyled = (props: any) => {
  return (
    <Typography {...props} fontStyle="italic" variant="h2" textAlign="center">
      {props.children}
    </Typography>
  );
};

const End = (props: any) => {
  const [rand, setRand] = useState(Math.floor(Math.random() * 1000));
  const { end, hits } = useQuiz();

  useEffect(() => {
    setRand(Math.floor(Math.random() * 1000));
  }, [end]);

  useEffect(() => {
    console.log(rand);
  }, [rand]);

  return (
    <>
      <Stack
        direction="column"
        spacing={4}
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Stack direction="column" alignItems="center">
          <Typography
            variant={hits < 3 ? "h1" : "h2"}
            fontWeight={hits < 3 ? 600 : 400}
            color="white"
            textAlign="center"
            fontStyle="italic"
            sx={{ mt: 0 }}
          >
            {hits < 3 && (
              <>
                {(rand & 1) === 0 && "Não desanime."}
                {(rand & 1) === 1 && "A vitória não veio."}
              </>
            )}
            {hits > 2 && (
              <>
                {(rand & 1) === 0 && "Assim nasce"}
                {(rand & 1) === 1 && "É ouroo!"}
              </>
            )}
          </Typography>
          <Typography
            variant={hits > 2 ? "h1" : "h2"}
            color="white"
            textAlign="center"
            fontWeight={hits > 2 ? 600 : 400}
            fontStyle="italic"
            sx={{ mt: 0 }}
          >
            {hits < 3 && (
              <>
                {(rand & 1) === 0 && "Você já é orgulho pro Brasil."}
                {(rand & 1) === 1 && "Partiu para mais um ciclo olímpico."}
              </>
            )}
            {hits > 2 && (
              <>
                {(rand & 1) === 0 && "um novo campeão!"}
                {(rand & 1) === 1 && "É do Brasil-sil-sill!"}
              </>
            )}
          </Typography>
        </Stack>
        <Stack direction="column" alignItems="center">
          <Typography
            variant="h4"
            color="white"
            textAlign="center"
            fontStyle="italic"
            sx={{ mt: 0 }}
          >
            Você teve {hits} acerto{hits > 1 ? "s" : ""}.
          </Typography>
          <Button
            startIcon={<RestartAltIcon fontSize="large" />}
            color="note"
            variant="outlined"
            size="large"
            onClick={() => {
              props.restart();
            }}
            sx={{
              fontSize: 30,
              fontStyle: "italic",
              p: "0 10px 0 10px",
              mt: 3,
            }}
          >
            Reiniciar
          </Button>
        </Stack>
      </Stack>
      <Box
        width="100%"
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          backgroundImage: `url(${BgFooter})`,
          backgroundPosition: "top center",
          backgroundSize: "100%",
          backgroundRepeat: "no-repeat",
          py: 10,
          textAlign: "center",
          zIndex: 1,
        }}
      >
        <img src={LogoSportv} width={150} />
      </Box>
    </>
  );
};

export default End;
